import React from "react";
import Info from "../components/Info";

const Contact = () => {
  return (
    <div>
      <Info />
    </div>
  );
};

export default Contact;
